<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>Fluoroscopy</h1>
            <p>
              Fluoroskopi adalah cara pemeriksaan yang menggunakan sifat tembus
              sinar rotngen dan suatu tabir yang bersifat luminisensi bila
              terkena sinar tersebut. Fluoroskopi terutama diperlukan untuk
              menyelidiki fungsi serta pergerakan suatu organ atau sistem tubuh
              seperti dinamika alat peredaran darah, misalnya jantung, dan
              pembuluh darah besar, serta pernafasan berupa pergerakan diafragma
              dan aerasi paru-paru.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "Fluoroscopy RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>
